import { DynamicFC } from 'holded/models/DynamicTypes';
import { CategoryRes } from 'holded/modules/cms/domain/collection/category';
import { GuideDataRes } from 'holded/modules/cms/domain/collection/guide';
import { GuideCollectionDataRes } from 'holded/modules/cms/domain/collection/guideCollection';
import { IntegrationDataRes } from 'holded/modules/cms/domain/collection/integration';
import { MarkupRes } from 'holded/modules/cms/domain/collection/markup';
import { SocialMediasRes } from 'holded/modules/cms/domain/collection/socialMedia';
import { SuccessCasesDataRes } from 'holded/modules/cms/domain/collection/successCase';
import { WebinarDataRes } from 'holded/modules/cms/domain/collection/webinar';
import { ButtonType, Link } from 'holded/modules/cms/domain/components/link';
import { NewsletterFooterSection } from 'holded/modules/cms/domain/components/newsletter';
import { TopBarSection } from 'holded/modules/cms/domain/components/topbar';
import { ImageMedia, ImageRes } from 'holded/modules/cms/domain/imageMedia';

const BASE_URL = process.env.NEXT_PUBLIC_URL;

type LanguageCode = 'es' | 'en';

type PageAttributes = {
  shortName: string;
  slug: string | null;
  slugLang: string;
  createdAt?: string;
  updatedAt?: string;
  publishedAt?: string;
  locale: LanguageCode;
  sections: DynamicFC[];
  options: PageOptionsType;
  localizations?: {
    data: Page[];
  };
  metadata: Metadata;
  category?: CategoryRes;
  markup?: MarkupRes;
};

type Page = {
  id?: number;
  attributes?: PageAttributes;
  isDetailPage?: boolean;
  localizationSlugs?: LocalizationSlug[];
};

type Metadata = {
  id?: number;
  metaTitle: string;
  metaDescription: string;
  keywords: string;
  shareMedia?: ImageMedia;
  preventIndexing: boolean;
};

type HeaderRes = {
  data: HeaderSection;
};

type HeaderSection = {
  id?: number;
  attributes?: {
    createdAt: string;
    updatedAt: string;
    locale: string;
    logo: ImageRes;
    logoDark: ImageRes;
    darkMode: boolean;
    buttons: ButtonType[];
    buttonsOnMobile?: ButtonType[];
    navbar: Navbar;
    topBar: [TopBarSection];
    localizations: {
      data: Page[];
    };
    hideLanguageSwitcher?: boolean;
  };
};

type Header = {
  createdAt?: string;
  updatedAt?: string;
  locale?: string;
  logo?: ImageRes;
  buttons: ButtonType[];
  navbar: Navbar;
  topBar?: [TopBarSection];
  localizations?: {
    data: Page[];
  };
};

type FooterSection = {
  id?: number;
  attributes?: Footer;
};

type Footer = {
  createdAt?: string;
  updatedAt?: string;
  locale?: string;
  copyright: string;
  languageLabel: string;
  columns: FooterColumn[];
  newsletter: NewsletterFooterSection;
  socialMedias?: SocialMediasRes;
};

type FooterColumn = {
  id?: number;
  title: string;
  items: Link[];
};

type FooterRes = {
  data: FooterSection;
};

type GlobalSection = {
  id?: number;
  attributes?: GlobalAttributes;
};

type GlobalAttributes = {
  createdAt?: string;
  updatedAt?: string;
  publishedAt?: string;
  locale?: string;
  favicon?: { data: ImageMedia };
  rating: Rating;
  organization?: Organization;
  localizations?: { data: Page[] };
};

type GlobalData = {
  global: GlobalSection | null;
  header: HeaderSection | null;
  footer: FooterSection | null;
};

type Rating = {
  id?: number;
  value: string;
  count: string;
};

type Organization = {
  id?: number;
  name: string;
  legalName: string;
  website: string;
  foundingDate: string;
  founders: { id?: number; name: string }[];
  addressData: {
    id?: number;
    address: string;
    locality: string;
    region: string;
    postalCode: string;
    country: string;
  };
  contact: {
    id?: number;
    type: string;
    phone: string;
    email: string;
  };
  socialMedia?: [{ id: number; url: string }];
};

type Navbar = {
  id?: number;
  navbarItems: {
    data: [NavbarItem];
  };
};

type IconTheme = 'features' | 'companies' | 'resources' | 'soft' | 'basic' | 'dark' | 'light' | 'linear';

type FooterNavbarAttributes = {
  title: string;
  tagText: string;
  description: string;
  href: string;
};

type NavbarItem = {
  id: number;
  attributes: NavbarItemAttributes;
};

type NavbarSubItem = {
  id?: number;
  href: string;
  name: string;
  newTab: boolean;
  originalSlug: string;
  description: string;
  icon: string;
  image?: ImageMedia;
};

type NavbarItemAttributes = {
  name: string;
  href: string;
  originalSlug: string;
  items: NavbarSubItem[];
  footer: FooterNavbarAttributes;
  newTab: boolean;
  type: 'normal' | 'media';
  iconTheme: IconTheme;
  orientation: 'left' | 'right';
  mediaDropdownAlignment: 'default' | 'vertical';
  locale?: string;
  createdAt?: string;
  updatedAt?: string;
  publishedAt?: string;
};
type GlobalRes = {
  data: GlobalSection;
};

type PageDataRes = {
  data: [Page];
};

type PageOptionsType = {
  intercom: boolean;
  darkMode?: boolean;
};

type Local = {
  id: number;
  code: LanguageCode;
  isDefault: boolean;
  name: string;
};

type PathType = {
  type: 'landing' | 'integration' | 'success-case' | 'webinar' | 'webinar-register';
  path: string | undefined;
};

type LanguageAPICode = LanguageCode | 'all';

type CmsRepository = {
  getGlobal(locale: LanguageCode): Promise<GlobalRes | undefined>;
  getHeader(locale: LanguageCode): Promise<HeaderRes | undefined>;
  getFooter(locale: LanguageCode): Promise<FooterRes | undefined>;
  getLocales(): Promise<Local[]>;
  getPage(slug: string[], locale: LanguageAPICode): Promise<PageDataRes>;
  getPages(): Promise<PageDataRes>;
  getIntegrations(): Promise<IntegrationDataRes>;
  getSuccessCases(): Promise<SuccessCasesDataRes>;
  getWebinars(): Promise<WebinarDataRes>;
  getGuideCollection(): Promise<GuideCollectionDataRes>;
  getGuides(): Promise<GuideDataRes>;
};

const excludePages = [
  'app',
  'guide-collection',
  'guide',
  'webinar',
  'integration',
  'webinar-register',
  'success-case',
  'partner',
  'error',
];

const getPath = ({
  slug,
  locale,
  defaultLocale,
}: {
  slug: string;
  locale: string;
  defaultLocale: LanguageCode;
}): string => {
  const initialSlash = slug.startsWith('/') ? '' : '/';
  const localePath = locale === defaultLocale ? '' : `/${locale}`;
  const slugPath = slug ? `${initialSlash}${slug}` : '';

  if (slug.startsWith(`/${locale}`)) {
    return slug;
  }

  return `${localePath}${slugPath}`;
};

type LocalizationSlug = {
  locale: LanguageCode;
  slug: string | null;
};

type SpecialSiteType = {
  slug: Record<LanguageCode, string>;
  level: number;
  detailPage: string;
};

type ConsentProps =
  | string
  | {
      ad_storage: string;
      analytics_storage: string;
      functionality_storage: string;
      personalization_storage: string;
      security_storage: string;
      ad_personalization: string;
      ad_user_data: string;
      wait_for_update: number;
    };

export type {
  SpecialSiteType,
  PageDataRes,
  Page,
  PageAttributes,
  PageOptionsType,
  CmsRepository,
  FooterSection,
  FooterNavbarAttributes,
  FooterRes,
  Footer,
  FooterColumn,
  HeaderSection,
  HeaderRes,
  Header,
  Navbar,
  NavbarItem,
  NavbarItemAttributes,
  NavbarSubItem,
  GlobalSection,
  GlobalRes,
  GlobalData,
  GlobalAttributes,
  Metadata,
  Local,
  PathType,
  LanguageCode,
  LocalizationSlug,
  ConsentProps,
};

export { excludePages, getPath, BASE_URL };
